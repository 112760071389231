import React, { useState } from 'react'
import { EarthIcon } from '../../icons'
import { CountryStatePicker } from './CountryStatePicker'
import { Modal } from '../'
import { theme, VIEWER_COUNTRY_STATE, countryStateMap } from '../../../lib'

type Props = {
  color?: string
}

export const CountryPickerButton = ({ color }: Props) => {
  const [showDrawer, setShowDrawer] = useState(false)
  const countryStateToRender = countryStateMap[VIEWER_COUNTRY_STATE].name

  return theme.showCountryPicker ? (
    <>
      <button
        className="btn-icon flex items-center space-x-5"
        aria-label={countryStateToRender}
        onClick={() => setShowDrawer(!showDrawer)}
      >
        <EarthIcon color={color || 'black'} />
        <span className="max-[420px]:hidden">{countryStateToRender}</span>
      </button>

      <Modal onClose={() => setShowDrawer(!showDrawer)} isOpen={showDrawer}>
        <div className="drawer">
          <CountryStatePicker />
        </div>
      </Modal>
    </>
  ) : null
}
