import React from 'react'
import { openBrowserWindow } from '../../services'
import { intl, theme } from '../../lib'
import { Auth_ReadAndAgreeToThe, Common_TermsAndConditions, Common_PrivacyPolicy } from '../../translations/messages'

type Props = {
  checked: boolean
  onChange: () => void
}

export const TermsCheckbox = ({ checked, onChange }: Props) => {
  return (
    <div className="flex items-center">
      <input
        id="terms"
        type="checkbox"
        className="checkbox min-w-[1.3rem] border-[var(--colors-theme-login-text)]"
        aria-label="Accept terms"
        checked={checked}
        onChange={onChange}
      />

      <label htmlFor="terms" className="ml-3 text-[var(--colors-theme-login-text)]">
        {`${intl.formatMessage(Auth_ReadAndAgreeToThe)} `}
        <button className="btn-icon font-bold underline" onClick={() => openBrowserWindow(theme.privacyPolicy)}>
          {intl.formatMessage(Common_PrivacyPolicy)}
        </button>
        {` & `}
        <button className="btn-icon font-bold underline" onClick={() => openBrowserWindow(theme.termsOfService)}>
          {intl.formatMessage(Common_TermsAndConditions)}
        </button>
      </label>
    </div>
  )
}
