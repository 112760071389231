import React from 'react'
import { useParams } from 'react-router-dom'
import { useAchievementsQuery } from '../../hooks'
import { Achievement } from './common'
import { Loading } from '../shared'
import { intl } from '../../lib'
import { AchievementDetailsPage_InvalidAchievement, AchievementDetailsPage_Levels } from '../../translations/messages'

export const AchievementDetailsPage = () => {
  const { achievementName } = useParams()
  const { data } = useAchievementsQuery()

  if (!data) {
    return <Loading className="centerAbsolute" />
  }

  const achievement = data.allAchievements.find(achievement => achievement.achievementName === achievementName)

  if (!achievement) {
    return (
      <div className="alert danger centerAbsolute">{intl.formatMessage(AchievementDetailsPage_InvalidAchievement)}</div>
    )
  }

  // The last finished achievement is always current level minus one
  // except when current level is 0 (i.e achievement not started)
  const lastFinishedAchievementLevel = achievement.currentLevel > 0 ? achievement.currentLevel - 1 : 0

  return (
    <div>
      <div className="p-10 bg-gradient-to-tl from-[var(--colors-theme-achievements-secondary)] to-[var(--colors-theme-achievements-primary)]">
        <Achievement achievement={{ ...achievement, currentLevel: lastFinishedAchievementLevel }} large />
      </div>

      <div className="max-w-[var(--max-page-width)] mx-auto">
        <p className="mt-5 ml-5">
          <b>{intl.formatMessage(AchievementDetailsPage_Levels)}</b>
        </p>

        {achievement.levels.map((level, index) => {
          const currentLevel = index
          const progress = index > achievement.currentLevel ? 0 : achievement.progress
          const showUnknownBadge = progress === 0 && currentLevel !== 0

          return (
            <div key={level}>
              <Achievement
                showUnknownBadge={showUnknownBadge}
                achievement={{
                  ...achievement,
                  currentLevel,
                  progress
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
