import React, { useState } from 'react'
import { BagdropButton } from './BagdropButton'
import { QRCodeButton } from './QRCodeButton'
import { BarcodeButton } from './BarcodeButton'
import { ActivePayout } from './ActivePayout'
import { Settings } from './Settings'
import { Modal } from '../../shared'
import { hasWebCameraScannerSupport } from '../../../services'
import { MenuIcon } from '../../icons'
import { countryStateMap, intl, VIEWER_COUNTRY_STATE, FIREBASE } from '../../../lib'
import { Header_Greeting, Header_ReadyToRecycle } from '../../../translations/messages'

type Props = {
  barcode: string
}

export const Header = ({ barcode }: Props) => {
  const [showSettings, setShowSettings] = useState(false)
  const jwtName = FIREBASE.auth.currentUser?.displayName || ''
  const { features } = countryStateMap[VIEWER_COUNTRY_STATE]

  return (
    <>
      <div className="w-full bg-gradient-to-tl from-[var(--colors-theme-home-secondary)] to-[var(--colors-theme-home-primary)]">
        <div className="relative p-5 max-w-[var(--max-page-width)] mx-auto space-y-10">
          <button
            className="btn-icon absolute top-5 right-5"
            title="settings"
            onClick={() => setShowSettings(!showSettings)}
          >
            <MenuIcon size="2.33rem" color="var(--colors-theme-home-text)" />
          </button>

          <div className="ml-5 text-[var(--colors-theme-home-text)]">
            <h1 className="text-3xl mb-2">
              {intl.formatMessage(Header_Greeting, { name: jwtName === 'Anonymous' ? '' : jwtName })}
            </h1>

            <span>{intl.formatMessage(Header_ReadyToRecycle)}</span>
          </div>

          <div className="relative flex items-center justify-around">
            <BarcodeButton barcodeValue={barcode} countryStateFeatures={features} />
            {hasWebCameraScannerSupport() && <BagdropButton countryStateFeatures={features} />}
            {hasWebCameraScannerSupport() && <QRCodeButton countryStateFeatures={features} />}
          </div>

          <ActivePayout />
        </div>
      </div>

      <Modal onClose={() => setShowSettings(!showSettings)} isOpen={showSettings}>
        <div className="drawer right w-[80vw] max-w-[var(--max-page-width)]">
          <Settings />
        </div>
      </Modal>
    </>
  )
}
