import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { logError, logInfo } from '@tomra/datadog-browser-logging'
import { feedbackMessages } from './feedbackMessages'
import { TextField, Loading, BackButton } from '../shared'
import { LockIcon, UserIcon, ArrowRightIcon } from '../icons'
import { intl, setItemInWebStorage, getItemFromWebStorage, theme } from '../../lib'
import { sendSignInLink } from '../../services'
import { Auth_SignIn, Common_Continue, Common_Email, Auth_SignInDescription } from '../../translations/messages'

export const SignInPage = () => {
  const navigate = useNavigate()
  const [emailValue, setEmailValue] = useState('')
  const [emailValid, setEmailValid] = useState(true)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [feedbackMessage, setFeedbackMessage] = useState('')

  useEffect(() => {
    getItemFromWebStorage('emailForSignIn').then(email => setEmailValue(email))
  }, [])

  const sendLoginEmail = async (evt: React.FormEvent<HTMLFormElement>) => {
    try {
      evt.preventDefault()
      setRequestStatus('loading')
      await setItemInWebStorage('emailForSignIn', emailValue)
      await sendSignInLink(emailValue, `${window.location.origin}/login/email-link`, 'LOGIN')
      navigate('/login/login-link-sent', { state: { email: emailValue } })
    } catch (error: any) {
      if (error.status === 412) {
        // User has set up with password login from manager
        navigate('/login/password', { state: { email: emailValue } })
      } else if (error.status === 409) {
        // Force user over from MT to R&E
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages['mytomra-in-nsw'])
        logError(new Error('Attempted R&E login in MT'), error, { email: emailValue })
      } else if (error.status === 400) {
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages['auth/invalid-email'])
        logInfo(`Attempted login with invalid email. Error: ${error.message}`, { email: emailValue })
      } else {
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages[error.code] || feedbackMessages.failed)
        logError(new Error('Failed to send login link'), error, { email: emailValue })
      }
    }
  }

  return (
    <div className="animate-fadeIn">
      <BackButton to="/login" textColor="var(--colors-theme-login-text)" />

      <form onSubmit={sendLoginEmail} className="flex flex-col justify-around min-h-[90vh]">
        <LockIcon className="mx-auto block" size="5rem" color="var(--colors-theme-login-text)" />

        <h1 className="text-3xl text-[var(--colors-theme-login-text)]">{intl.formatMessage(Auth_SignIn)}</h1>

        <p className="text-[var(--colors-theme-login-text)]">
          {intl.formatMessage(Auth_SignInDescription, { appName: theme.name })}
        </p>

        <TextField
          onChange={(value, valid) => {
            setEmailValue(value.toLowerCase())
            setEmailValid(valid)
          }}
          value={emailValue}
          label={intl.formatMessage(Common_Email)}
          autoComplete="username"
          type="email"
          labelClassNames="text-[var(--colors-theme-login-text)]"
          ignoreWhitespace
          required
        />

        {feedbackMessage && <div className="alert danger">{feedbackMessage}</div>}

        <button
          className="btn flex items-center justify-between"
          type="submit"
          disabled={!emailValue || !emailValid || requestStatus === 'loading'}
        >
          {requestStatus === 'loading' ? (
            <Loading className="mx-auto" />
          ) : (
            <>
              <UserIcon />
              <p>{intl.formatMessage(Common_Continue)}</p>
              <ArrowRightIcon />
            </>
          )}
        </button>
      </form>
    </div>
  )
}
