import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { logError } from '@tomra/datadog-browser-logging'
import { TextField, BackButton } from '../shared'
import { EmailSearchIcon } from '../icons'
import { intl, setItemInWebStorage } from '../../lib'
import { Auth_EnterEmailToComplete, Auth_AlmostThere, Common_Email, Common_Continue } from '../../translations/messages'

export const ConfirmEmailPage = () => {
  const navigate = useNavigate()
  const [emailValue, setEmailValue] = useState('')
  const [emailValid, setEmailValid] = useState(true)

  const saveAndContinue = async (evt?: React.FormEvent<HTMLFormElement>) => {
    try {
      evt?.preventDefault()
      await setItemInWebStorage('emailForSignIn', emailValue)
      navigate('/login/email-link' + window.location.search, { replace: true })
    } catch (error: any) {
      logError(new Error('Failed to save email and continue'), error)
    }
  }

  return (
    <>
      <BackButton to="/login" textColor="var(--colors-theme-login-text)" />

      <div className="flex flex-col justify-around min-h-[90vh]">
        <div className="mx-auto block">
          <EmailSearchIcon size="5rem" color="var(--colors-theme-login-text)" />
        </div>

        <h1 className="text-3xl text-[var(--colors-theme-login-text)]">{intl.formatMessage(Auth_AlmostThere)}</h1>

        <p className="text-[var(--colors-theme-login-text)]">{intl.formatMessage(Auth_EnterEmailToComplete)}</p>

        <form onSubmit={saveAndContinue} className="space-y-5">
          <TextField
            onChange={(value, valid) => {
              setEmailValue(value.toLowerCase())
              setEmailValid(valid)
            }}
            value={emailValue}
            label={intl.formatMessage(Common_Email)}
            autoComplete="username"
            type="email"
            labelClassNames="text-[var(--colors-theme-login-text)]"
            ignoreWhitespace
            required
          />

          <button className="btn w-full" type="submit" disabled={!emailValid} aria-disabled={emailValid}>
            {intl.formatMessage(Common_Continue)}
          </button>
        </form>
      </div>
    </>
  )
}
