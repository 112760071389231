import React from 'react'
import { type UseMutationResult } from '@tanstack/react-query'
import { Loading } from '../../shared'
import { intl } from '../../../lib'
import { authenticatePayPalUser } from '../../../services'
import {
  Common_Continue,
  PayoutSettings_PayPal_Description,
  PayoutSettings_VoucherDisclaimer,
  Common_EditPayout,
  Common_UseSavedAccount,
  Common_DeleteSaved
} from '../../../translations/messages'

type Props = {
  savedConfiguration: PayoutMethodType['savedConfiguration']
  active: boolean
  updateQuery: UseMutationResult<any, Error, PayoutUpdateType, unknown>
}

export const PayPal = ({ savedConfiguration, active, updateQuery }: Props) => (
  <>
    <h1 className="text-3xl">{intl.formatMessage(Common_EditPayout)}</h1>
    <span>{intl.formatMessage(PayoutSettings_PayPal_Description)}</span>
    <span>{intl.formatMessage(PayoutSettings_VoucherDisclaimer, { payoutMethod: 'PayPal' })}</span>

    {savedConfiguration ? (
      <button
        className="btn danger"
        aria-disabled={updateQuery.isPending}
        disabled={updateQuery.isPending}
        onClick={() => updateQuery.mutate({ type: 'PAYPAL', method: active ? 'DELETE' : 'PUT' })}
      >
        {updateQuery.isPending ? (
          <Loading />
        ) : active ? (
          intl.formatMessage(Common_DeleteSaved)
        ) : (
          <span>
            <p className="leading-5">{intl.formatMessage(Common_UseSavedAccount)}</p>
            <p className="text-sm">({savedConfiguration.email})</p>
          </span>
        )}
      </button>
    ) : (
      <button className="btn primary" onClick={authenticatePayPalUser}>
        {intl.formatMessage(Common_Continue)}
      </button>
    )}
  </>
)
