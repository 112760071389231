import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Currency } from '../../shared/common'
import { calculateDaysBetween, intl } from '../../../lib'
import {
  Common_CampaignEnded,
  CampaignInfo_DaysLeft,
  CampaignInfo_EndingToday,
  Common_By,
  MyDonationsPage_YourContribution
} from '../../../translations/messages'
import { benefitToTranslationMap } from './charityTranslationMap'

type Props = {
  donation: CampaignDonationType
}

export const CharityDonationCard: FunctionComponent<Props> = ({ donation }) => {
  const { campaignLogoUrl, campaignName, charityDisplayName, contributionSum, benefit, endTime, campaignId, status } =
    donation
  const daysLeft = calculateDaysBetween(new Date().getTime(), endTime)
  const campaignHasEnded = status === 'STOPPED' || daysLeft < 0

  return (
    <Link to={`../${campaignId}`} className="card relative">
      <div className="flex items-center px-2 pb-5 pt-10 space-x-3">
        <small className="absolute top-2 right-5 text-right">
          {intl.formatMessage(benefitToTranslationMap[benefit])}
        </small>

        <img
          src={campaignLogoUrl}
          alt={`${campaignName} logo`}
          className="object-cover rounded-full border-2 border-[var(--colors-theme-charity-secondary)] w-20 h-20"
        />

        <div className="space-y-3">
          <div>
            <h2 className="font-bold">{campaignName}</h2>
            <p>
              {intl.formatMessage(Common_By)} {charityDisplayName}
            </p>
          </div>

          {campaignHasEnded ? (
            <p className="text-warm-red">{intl.formatMessage(Common_CampaignEnded)}</p>
          ) : daysLeft === 0 ? (
            <p>{intl.formatMessage(CampaignInfo_EndingToday)}</p>
          ) : (
            <p>{intl.formatMessage(CampaignInfo_DaysLeft, { daysLeft })}</p>
          )}
        </div>
      </div>

      <div className="border-t-2 text-[var(--colors-theme-charity-text)] border-[var(--colors-theme-charity-secondary)] p-3 flex flex-col items-center bg-gradient-to-tl from-[var(--colors-theme-charity-secondary)] to-[var(--colors-theme-charity-primary)] rounded-bl-3xl rounded-br-3xl">
        <p>{intl.formatMessage(MyDonationsPage_YourContribution)}</p>

        <h3 className="font-bold">
          <Currency amount={contributionSum || 0} currency={'AUD'} />
        </h3>
      </div>
    </Link>
  )
}
