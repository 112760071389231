import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { CloseIcon } from './icons'
import { Common_Close } from '../translations/messages'
import { intl } from '../lib'

let timeoutRef

export const AppMessages = () => {
  const [message, setMessage] = useState<AppMessageType>()

  useEffect(() => {
    window.addEventListener('pushAppMessage', (event: any) => setMessage(event.detail))
    window.addEventListener('removeAppMessage', () => setMessage(undefined))
  }, [])

  if (message && message.ttl) {
    window.clearTimeout(timeoutRef)
    window.setTimeout(() => {
      setMessage(undefined)
    }, message.ttl)
  }

  return message
    ? ReactDOM.createPortal(
        <div className="absolute top-0 left-0 p-3 z-50 w-full">
          <div
            className={`alert shadow-2xl flex items-center flex-row justify-between rounded-[var(--card-border-radius)] ${message?.type || 'info'}`}
          >
            <p className="break-words mx-3">{message?.formattedMessage}</p>
            <button
              className="btn-icon"
              onClick={() => setMessage(undefined)}
              aria-label={intl.formatMessage(Common_Close)}
            >
              <CloseIcon />
            </button>
          </div>
        </div>,
        document.getElementById('root')!
      )
    : null
}

export const pushMessage = (message: AppMessageType) => {
  window.dispatchEvent(new CustomEvent('pushAppMessage', { detail: message }))
}

export const removeMessage = () => {
  // @ts-ignore
  window.dispatchEvent(new CustomEvent('removeAppMessage'))
}
