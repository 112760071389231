import React, { useState } from 'react'
import { ActivityHistory } from './ActivityHistory'
import { Currency } from '../shared'
import { ArrowDownIcon, RecycleBottleIcon } from '../icons'
import { intl } from '../../lib'
import {
  ActivityRow_BagDeliveredAt,
  Common_BagRegistered,
  ActivityRow_PaidOut,
  ActivityRow_Pending,
  ActivityRow_PendingAction,
  ActivityRow_RecycledAt,
  ActivityRow_Expired
} from '../../translations/messages'

const textColorMap = {
  SUCCESSFUL: 'text-teal-dark',
  EXPIRED: 'text-warm-red',
  USER_ERROR: 'text-warm-red',
  SYSTEM_ERROR: 'text-warm-red'
}

const translationMap = {
  SUCCESSFUL: ActivityRow_PaidOut,
  EXPIRED: ActivityRow_Expired,
  USER_ERROR: ActivityRow_PendingAction,
  SYSTEM_ERROR: ActivityRow_PendingAction
}

interface Props {
  activity: RefundType
}

export const ActivityRow = ({ activity }: Props) => {
  const [showHistory, setShowHistory] = useState(false)
  const { id, activityType, location, refund, status, currency, activities } = activity
  const shouldShowRefundValue = status === 'SUCCESSFUL' && refund && currency
  const bagtag = activityType === 'BAG' ? id : ''
  const statusTextColor = textColorMap[status] || 'text-fire-orange-dark'
  const statusText = intl.formatMessage(translationMap[status] || ActivityRow_Pending)

  const toggleDetails = () => {
    setShowHistory(!showHistory)
  }

  return (
    <>
      <button
        id={`accButton-${activity.id}`}
        onClick={toggleDetails}
        aria-expanded={showHistory}
        aria-controls={`accSection-${activity.id}`}
        className="w-full card py-3 px-4 z-10 mt-3"
      >
        <div className="flex items-center justify-between space-x-5">
          <RecycleBottleIcon size="2rem" />

          <div className="truncateText">
            <p>
              {activityType === 'BAG' && location
                ? intl.formatMessage(ActivityRow_BagDeliveredAt, { location })
                : activityType === 'BAG' && !location
                  ? intl.formatMessage(Common_BagRegistered)
                  : intl.formatMessage(ActivityRow_RecycledAt, { location })}
            </p>

            <small className={`space-x-2 font-bold ${statusTextColor}`}>
              {shouldShowRefundValue && (
                <span>
                  <Currency amount={refund} currency={currency} />
                </span>
              )}
              <span>{statusText}</span>
            </small>
          </div>

          <ArrowDownIcon />
        </div>
      </button>

      {showHistory && (
        <div id={`accSection-${activity.id}`} role="region" aria-labelledby={`accButton-${activity.id}`}>
          <ActivityHistory bagtag={bagtag} activities={activities} status={status} />
        </div>
      )}
    </>
  )
}
