import React, { useEffect, useState } from 'react'
import ReactBarcode from 'react-barcode'
import { CloseIcon, BarcodeIcon } from '../../icons'
import { getItemFromWebStorage, setItemInWebStorage, intl, STAGE } from '../../../lib'
import { BarcodeButton_Description, Header_Barcode, Common_Close } from '../../../translations/messages'

type Props = {
  barcodeValue: string | undefined
  countryStateFeatures: CountryType['features']
}

export const BarcodeButton = ({ barcodeValue, countryStateFeatures }: Props) => {
  const barcodeIsOnlyChoice = !countryStateFeatures.HAS_BAGDROP && !countryStateFeatures.HAS_QR_CODE && STAGE === 'prod'
  const [expandBarcode, setExpandBarcode] = useState(barcodeIsOnlyChoice)

  const toggleBarcodeExpansion = () => {
    setItemInWebStorage('expandBarcode', !expandBarcode)
    setExpandBarcode(!expandBarcode)
  }

  useEffect(() => {
    getItemFromWebStorage('expandBarcode').then(expanded => expanded && setExpandBarcode(true))
  }, [])

  return barcodeValue && (STAGE === 'test' || countryStateFeatures.HAS_BARCODE) ? (
    <>
      <button
        className="card shadow-2xl p-0 rounded-full flex flex-col items-center justify-center h-[100px] w-[100px]"
        onClick={toggleBarcodeExpansion}
      >
        <BarcodeIcon color="black" size="2.3rem" />
        <small className="leading-3 font-light">{intl.formatMessage(Header_Barcode)}</small>
      </button>

      {expandBarcode && (
        <div className="card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center w-[93vw] max-w-[var(--max-page-width)] z-10">
          {/* @ts-ignore */}
          <ReactBarcode renderer="img" value={barcodeValue} displayValue={false} height={65} />

          <small className="leading-3 mb-2">{intl.formatMessage(BarcodeButton_Description)}</small>

          {!barcodeIsOnlyChoice && (
            <button
              className="btn-icon absolute top-3 right-3"
              onClick={toggleBarcodeExpansion}
              aria-label={intl.formatMessage(Common_Close)}
            >
              <CloseIcon />
            </button>
          )}
        </div>
      )}
    </>
  ) : null
}
