import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loading, TextField } from '../../../shared'
import { validateAccount, createZeptoContact } from '../../../../services'
import { intl } from '../../../../lib'
import {
  PayoutSettings_BankTransfer_Disclaimer_Three,
  PayoutSettings_BankTransfer_BsbNumber,
  PayoutSettings_BankTransfer_BsbNumber_Invalid,
  PayoutSettings_BankTransfer_AccountNumber,
  PayoutSettings_BankTransfer_AccountNumber_Invalid,
  PayoutSettings_BankTransfer_BsbNumber_NotFound,
  PayoutSettings_BankTransfer_Confirmation,
  Common_Validate,
  Common_EditPayout,
  Common_Confirm,
  Common_GeneralError,
  Common_Institution,
  Common_Branch,
  Common_Account,
  Common_Cancel,
  PayoutSettings_BankTransfer_AccountHolder,
  PayoutSettings_BankTransfer_AccountHolder_Invalid
} from '../../../../translations/messages'

//letters, numbers, spaces and the following characters: +@!^$\u0026'()*-:;=?.#_,[]/æøå
// eslint-disable-next-line no-useless-escape, prettier/prettier
const validAccountHolderRegExp = /[a-zA-Z0-9 +@!\\^$&'\(\)*-:;=?.#_,\[\]\/æøåÆØÅ]+/

export const Setup = () => {
  const [zeptoRequestStatus, setZeptoRequestStatus] = useState<RequestStatusType>('initial')
  const [bsbNumber, setBsbNumber] = useState('')
  const [bsbNumberValid, setBsbNumberValid] = useState(false)
  const [accountHolder, setAccountHolder] = useState('')
  const [accountHolderValid, setAccountHolderValid] = useState(false)
  const [accountNumber, setAccountNumber] = useState('')
  const [accountNumberValid, setaccountNumberValid] = useState(false)
  const [institution, setInstitution] = useState('')
  const [branch, setBranch] = useState('')
  const [backendValidatedStatus, setBackendValidatedStatus] = useState<
    'branch-invalid' | 'account-invalid' | RequestStatusType
  >('initial')
  const navigate = useNavigate()
  const validateButtonDisabled = !(
    accountNumber &&
    accountNumberValid &&
    bsbNumber &&
    bsbNumberValid &&
    accountHolder &&
    accountHolderValid
  )

  const _validateAccount = async () => {
    try {
      setBackendValidatedStatus('loading')
      const { institution: _institution, branch: _branch } = await validateAccount(
        bsbNumber,
        accountNumber,
        accountHolder
      )
      setInstitution(_institution)
      setBranch(_branch)
      setBackendValidatedStatus('success')
    } catch (error: any) {
      if (error.status === 404) setBackendValidatedStatus('branch-invalid')
      else if (error.status === 412) setBackendValidatedStatus('account-invalid')
      else setBackendValidatedStatus('failed')
    }
  }

  const _createZeptoContact = async () => {
    try {
      setZeptoRequestStatus('loading')
      const contactId = await createZeptoContact(bsbNumber, accountNumber, accountHolder)
      setZeptoRequestStatus('success')
      redirectWithRecipient({ type: 'BANK_TRANSFER', recipient: contactId })
    } catch (error) {
      setZeptoRequestStatus('failed')
    }
  }

  const redirectWithRecipient = (payload?: { type?: string; recipient?: string; errorMessage?: string }) => {
    const encodedpayload = payload && btoa(JSON.stringify(payload))
    navigate(`/?encodedpayload=${encodedpayload}`, { replace: true })
  }

  return (
    <>
      <h1 className="text-3xl">{intl.formatMessage(Common_EditPayout)}</h1>

      {backendValidatedStatus === 'initial' || backendValidatedStatus !== 'success' ? (
        <>
          <p>{intl.formatMessage(PayoutSettings_BankTransfer_Disclaimer_Three)}</p>

          <TextField
            type="text"
            pattern={validAccountHolderRegExp.source}
            label={intl.formatMessage(PayoutSettings_BankTransfer_AccountHolder)}
            value={accountHolder}
            onChange={(value, valid) => {
              setAccountHolderValid(valid)
              setAccountHolder(value)
            }}
            patternErrorMsg={intl.formatMessage(PayoutSettings_BankTransfer_AccountHolder_Invalid)}
            required
          />

          <TextField
            type="number"
            label={intl.formatMessage(PayoutSettings_BankTransfer_BsbNumber)}
            value={bsbNumber}
            onChange={(value, valid) => {
              setBsbNumberValid(valid)
              setBsbNumber(value)
            }}
            pattern="^\d{6}$"
            patternErrorMsg={intl.formatMessage(PayoutSettings_BankTransfer_BsbNumber_Invalid)}
            required
          />

          <TextField
            type="number"
            label={intl.formatMessage(PayoutSettings_BankTransfer_AccountNumber)}
            value={accountNumber}
            onChange={(value, valid) => {
              setaccountNumberValid(valid)
              setAccountNumber(value)
            }}
            pattern="^\d{5,9}$"
            patternErrorMsg={intl.formatMessage(PayoutSettings_BankTransfer_AccountNumber_Invalid)}
            required
          />
        </>
      ) : (
        <>
          <p>{intl.formatMessage(PayoutSettings_BankTransfer_Confirmation)}</p>

          <div>
            <b>
              <small>{intl.formatMessage(PayoutSettings_BankTransfer_AccountHolder)}</small>
            </b>
            <p className="text-teal-dark">{accountHolder}</p>

            <b>
              <small>{intl.formatMessage(Common_Account)}</small>
            </b>
            <p className="text-teal-dark">{`${bsbNumber.slice(0, 3)}-${bsbNumber.slice(3)} ${accountNumber}`}</p>

            <b>
              <small>{intl.formatMessage(Common_Institution)}</small>
            </b>
            <p className="text-teal-dark">{institution}</p>

            <b>
              <small>{intl.formatMessage(Common_Branch)}</small>
            </b>
            <p className="text-teal-dark">{branch}</p>
          </div>
        </>
      )}

      {backendValidatedStatus === 'branch-invalid' ? (
        <div className="alert danger">{intl.formatMessage(PayoutSettings_BankTransfer_BsbNumber_NotFound)}</div>
      ) : backendValidatedStatus === 'account-invalid' ? (
        <div className="alert danger">{intl.formatMessage(PayoutSettings_BankTransfer_AccountNumber_Invalid)}</div>
      ) : backendValidatedStatus === 'failed' || zeptoRequestStatus === 'failed' ? (
        <div className="alert danger">{intl.formatMessage(Common_GeneralError)}</div>
      ) : null}

      {backendValidatedStatus === 'success' ? (
        <div className="flex flex-col space-y-3">
          <button
            className="btn primary"
            onClick={_createZeptoContact}
            disabled={backendValidatedStatus !== 'success'}
            aria-disabled={backendValidatedStatus !== 'success'}
          >
            {zeptoRequestStatus === 'loading' ? <Loading /> : intl.formatMessage(Common_Confirm)}
          </button>

          <button className="btn" onClick={() => setBackendValidatedStatus('initial')}>
            {intl.formatMessage(Common_Cancel)}
          </button>
        </div>
      ) : (
        <button
          className="btn"
          onClick={_validateAccount}
          disabled={validateButtonDisabled}
          aria-disabled={validateButtonDisabled}
        >
          {backendValidatedStatus === 'loading' ? <Loading /> : intl.formatMessage(Common_Validate)}
        </button>
      )}
    </>
  )
}
