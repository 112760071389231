import React from 'react'
import styled from 'styled-components'
import { default as ReactBarcode } from 'react-barcode'
import { Currency } from '../shared'
import { intl } from '../../lib'
import { storeChainLogoMap } from './storeChainLogoMap'
import {
  VoucherCard_AmountDisclaimer,
  VoucherCard_Created,
  VoucherCard_RedeemAt,
  VoucherCard_ValidUntil,
  VoucherCard_Redeemed
} from '../../translations/messages'

const BarcodeContainer = styled.div`
  rect {
    fill: #7f7f7f;
  }
`

type Props = {
  voucher: VoucherType
}

export const VoucherCard = (props: Props) => {
  const { voucher } = props
  const { redeemed, expired, barcode, storeChain, createdDate, value, currency, expireDate } = voucher
  const inactive = redeemed || expired

  const storeChainLogo = storeChainLogoMap[storeChain.toLowerCase()] || storeChainLogoMap.default

  return (
    <div className="grow-0 shrink-0 basis-full relative">
      <div className="card flex flex-col items-center p-5 cursor-pointer space-y-8">
        <div>
          <b className={`text-center block mb-2 ${inactive ? 'line-through' : ''}`}>
            {intl.formatMessage(VoucherCard_RedeemAt)}
          </b>
          {storeChainLogo ? <img src={storeChainLogo} alt={storeChain} width="130px" /> : storeChain}
        </div>

        <div>
          <h1 className="text-3xl text-center">
            <Currency amount={value} currency={currency} />
          </h1>
          <small className="font-bold">{intl.formatMessage(VoucherCard_AmountDisclaimer)}</small>
        </div>

        <BarcodeContainer data-dd-privacy="hidden">
          <ReactBarcode value={barcode} height={70} width={1.2} fontSize={10} />
        </BarcodeContainer>

        {inactive ? (
          <p className="text-warm-red">{intl.formatMessage(VoucherCard_Redeemed)}</p>
        ) : (
          <div className="flex flex-col items-center">
            <small className="font-bold">
              {intl.formatMessage(VoucherCard_Created, { date: intl.formatDate(createdDate) })}
            </small>

            <small className="font-bold">
              {intl.formatMessage(VoucherCard_ValidUntil, { date: intl.formatDate(expireDate) })}
            </small>
          </div>
        )}
      </div>
    </div>
  )
}
