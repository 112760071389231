import React from 'react'
import { intl } from '../../../lib'
import { openBrowserWindow } from '../../../services'
import { CharityInfoDrawer_VisitWebsite } from '../../../translations/messages'
import { Modal } from '../../shared/common'

type Props = {
  charityDisplayName: string
  charityABN: string
  charityDescription: string
  charityWebsiteUrl: string | null
  onClose: () => void
  visible: boolean
}

export const CharityInfoDrawer = ({
  charityDisplayName,
  charityABN,
  charityDescription,
  charityWebsiteUrl,
  onClose,
  visible
}: Props) => {
  const onCharityLinkClick = () => {
    const normalizedCharityUrl = charityWebsiteUrl?.match(/^(http|https)/g)
      ? charityWebsiteUrl
      : `https://${charityWebsiteUrl}`

    openBrowserWindow(normalizedCharityUrl)
  }

  return (
    <Modal onClose={onClose} isOpen={visible}>
      <div className="drawer !bg-gradient-to-tl from-[var(--colors-theme-charity-secondary)] to-[var(--colors-theme-charity-primary)] p-10">
        <div className="max-w-[var(--max-page-width)] mx-auto flex flex-col items-center space-y-5">
          <h1 className="text-3xl">{charityDisplayName}</h1>

          <div className="flex items-center space-x-2">
            <b style={{ marginRight: 'var(--spacing-xs)' }}>ABN</b>
            <p>{charityABN}</p>
          </div>

          <p>{charityDescription}</p>

          {charityWebsiteUrl && (
            <button
              className="btn-icon underline cursor-pointer"
              onClick={onCharityLinkClick}
              aria-label={intl.formatMessage(CharityInfoDrawer_VisitWebsite)}
            >
              {intl.formatMessage(CharityInfoDrawer_VisitWebsite)}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}
