import React from 'react'
import { Outlet } from 'react-router-dom'

export const LoginLayout = () => (
  <div className="min-h-[100vh] bg-gradient-to-tl from-[var(--colors-theme-login-secondary)] to-[var(--colors-theme-login-primary)] p-8">
    <div className="max-w-[var(--max-page-width)] mx-auto">
      <Outlet />
    </div>
  </div>
)
