import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { logError, logInfo } from '@tomra/datadog-browser-logging'
import { feedbackMessages } from './feedbackMessages'
import { TermsCheckbox } from './TermsCheckbox'
import { TextField, Loading, InfoTooltip, BackButton } from '../shared'
import { PersonQuestionIcon, UserIcon, ArrowRightIcon } from '../icons'
import { theme, intl, setItemInWebStorage } from '../../lib'
import { sendSignInLink } from '../../services'
import {
  Auth_Register,
  Auth_FirstNameDisclaimer,
  Auth_RegisterDescription,
  Common_Email,
  Common_SubscribeToNewsletter,
  Common_Continue,
  Auth_Name
} from '../../translations/messages'

export const RegisterPage = () => {
  const navigate = useNavigate()
  const [nameValue, setNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [emailValid, setEmailValid] = useState(true)
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('initial')
  const [hasAcceptedNewsletter, setHasAcceptedNewsletter] = useState(false)
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState('')

  const sendLoginEmail = async (evt: React.FormEvent<HTMLFormElement>) => {
    try {
      evt.preventDefault()
      setRequestStatus('loading')
      await sendSignInLink(emailValue, `${window.location.origin}/login/email-link`, 'REGISTER')
      await setItemInWebStorage('emailForSignIn', emailValue)
      await setItemInWebStorage('nameForSignIn', nameValue)
      await setItemInWebStorage('newsletterForSignIn', hasAcceptedNewsletter)
      navigate('/login/login-link-sent', {
        state: { email: emailValue, isRegisterFlow: true }
      })
    } catch (error: any) {
      if (error.status === 412) {
        // User has set up with password login from manager
        navigate('/login/password', { state: { email: emailValue } })
      } else if (error.status === 409) {
        // Force user over from MT to R&E
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages['mytomra-in-nsw'])
        logError(new Error('Attempted R&E login in MT'), error)
      } else if (error.status === 400) {
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages['auth/invalid-email'])
        logInfo(`Attempted login with invalid email. Error: ${error.message}`)
      } else {
        setRequestStatus('failed')
        setFeedbackMessage(feedbackMessages[error.code] || feedbackMessages.failed)
        logError(new Error('Failed to send login link'), error)
      }
    }
  }

  return (
    <>
      <BackButton to="/login" textColor="var(--colors-theme-login-text)" />

      <form onSubmit={sendLoginEmail} className="flex flex-col justify-around min-h-[90vh] space-y-5 mt-5">
        <PersonQuestionIcon className="mx-auto block" size="5rem" color="var(--colors-theme-login-text)" />

        <h1 className="text-3xl text-[var(--colors-theme-login-text)]">{intl.formatMessage(Auth_Register)}</h1>

        <p className="text-[var(--colors-theme-login-text)]">
          {intl.formatMessage(Auth_RegisterDescription, { appName: theme.name })}
        </p>

        <div className="space-y-5">
          <div className="relative">
            <TextField
              type="text"
              onChange={value => setNameValue(value)}
              value={nameValue}
              label={intl.formatMessage(Auth_Name)}
              autoComplete="given-name"
              labelClassNames="text-[var(--colors-theme-login-text)]"
              required
            />

            <div className="absolute top-1/2 right-5">
              <InfoTooltip
                content={intl.formatMessage(Auth_FirstNameDisclaimer, { appName: theme.name })}
                position="right"
              />
            </div>
          </div>

          <TextField
            onChange={(value, valid) => {
              setEmailValue(value.toLowerCase())
              setEmailValid(valid)
            }}
            value={emailValue}
            label={intl.formatMessage(Common_Email)}
            autoComplete="username"
            type="email"
            labelClassNames="text-[var(--colors-theme-login-text)]"
            ignoreWhitespace
            required
          />
        </div>

        <div className="space-y-3">
          <div className="flex space-x-3">
            <input
              id="newsletterCheckbox"
              type="checkbox"
              className="checkbox min-w-[1.3rem] border-[var(--colors-theme-login-text)]"
              aria-label="Subscribe to newsletter"
              onChange={evt => setHasAcceptedNewsletter(evt.target.checked)}
              checked={hasAcceptedNewsletter}
            />

            <label htmlFor="newsletterCheckbox" className="text-[var(--colors-theme-login-text)]">
              {intl.formatMessage(Common_SubscribeToNewsletter)}
            </label>
          </div>

          <TermsCheckbox
            checked={hasAcceptedTerms}
            onChange={() => {
              setHasAcceptedTerms(!hasAcceptedTerms)
              window.localStorage.setItem('hasAcceptedTerms', String(!hasAcceptedTerms))
            }}
          />
        </div>

        {feedbackMessage && <div className="alert danger">{feedbackMessage}</div>}

        <button
          className="btn flex items-center justify-between"
          type="submit"
          disabled={!emailValue || !emailValid || !nameValue || requestStatus === 'loading' || !hasAcceptedTerms}
        >
          {requestStatus === 'loading' ? (
            <Loading className="mx-auto" />
          ) : (
            <>
              <UserIcon />
              <span>{intl.formatMessage(Common_Continue)}</span>
              <ArrowRightIcon />
            </>
          )}
        </button>
      </form>
    </>
  )
}
