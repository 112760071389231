import React, { useRef, useEffect, FunctionComponent } from 'react'
import { createPortal } from 'react-dom'
import { CloseIcon } from '../../icons'

type Props = {
  children?: React.ReactNode
  isOpen: boolean
  onClose?: () => void
  variant?: string
}

export const Modal: FunctionComponent<Props> = ({ children, isOpen, onClose, variant = '' }) => {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!modalRef.current?.contains(event.target as Node)) {
        onClose?.()
      }
    }

    if (isOpen && onClose) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onClose])

  return createPortal(
    <div
      className={`modal ${variant} ${isOpen && 'open'} !h-[initial]`}
      ref={modalRef}
      role="dialog"
      aria-label="dialog"
    >
      {children}

      {onClose && (
        <button className="btn-icon absolute top-3 right-3" onClick={onClose} aria-label="close">
          <CloseIcon size="1.33rem" />
        </button>
      )}
    </div>,
    document.getElementById('root') as HTMLDivElement
  )
}
