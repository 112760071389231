import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { intl } from '../../../lib'
import { Common_By, BrowseCharitiesPage_Donations } from '../../../translations/messages'
import { benefitToTranslationMap } from './charityTranslationMap'

type Props = {
  campaign: CharityCampaignType
}

export const CharityCampaignCard: FunctionComponent<Props> = ({ campaign }) => {
  const { id, charityLogoUrl, charityBenefit, name, charityDisplayName, numberOfDonations } = campaign

  return (
    <Link to={id} className="card flex items-center space-x-3 relative px-2 py-5">
      <small className="absolute top-2 right-5 text-right">
        {intl.formatMessage(benefitToTranslationMap[charityBenefit])}
      </small>
      <img
        src={charityLogoUrl}
        alt={`${name} logo`}
        className="object-cover rounded-full border-2 border-[var(--colors-theme-charity-secondary)] w-20 h-20"
      />
      <div className="flex flex-col">
        <h2 className="font-bold">{name}</h2>
        {intl.formatMessage(Common_By)} {charityDisplayName}
        <div>{intl.formatMessage(BrowseCharitiesPage_Donations, { numberOfDonations })}</div>
      </div>
    </Link>
  )
}
